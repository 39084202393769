import React, { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formStates } from 'shared/constants/formStates';
import { useSessionApi } from 'shared/services/session';
import { useUserAccountApi } from 'shared/services/user';

import { Formik, Form, FormikHelpers, Field } from 'formik';
import * as Yup from 'yup';
import { routeDashboard } from 'routes';
import { useUser } from 'shared/hooks/useUser';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';
import { getLanguageByCode } from 'shared/functions/getLanguageByCode';
import { languages } from 'shared/constants/languages';
import { Loader, SystemAlert, Typography } from '../components';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { routePaths } from '../shared/constants/api.constants';

type UnauthenticatedAppProps = {};

const UnauthenticatedApp: FC<UnauthenticatedAppProps> = (): ReactElement => {
  const user = useUser();
  const { logIn } = useSessionApi();
  const { fetchUserAccountInfo } = useUserAccountApi();
  const { t, i18n } = useTranslation();

  const [formState, setFormState] = useState(formStates.PRISTINE);
  const [, setIsPending] = useState(false);
  const [accountIsBlocked, setAccountIsBlocked] = useState(false);

  type FormValues = {
    username: string;
    password: string;
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().min(2, t('INVALID_USERNAME')).required(),
    password: Yup.string().required(),
  });

  const initialValues: FormValues = {
    username: '',
    password: '',
  };

  const onLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const handleSubmit = async (
    { username, password }: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) => {
    setFormState(formStates.PRISTINE);
    setIsPending(true);
    setAccountIsBlocked(false);

    try {
      const {
        isBadRequest,
        access_token: accessToken,
        expires_in: expiresIn,
        refresh_token: refreshToken,
        errors,
      } = await logIn({
        username,
        password,
      });
      if (!isBadRequest && accessToken) {
        const { payload: accountInfo } = await fetchUserAccountInfo({
          token: accessToken,
        });

        user.logIn(
          {
            username,
            token: accessToken,
            expirationDate: Date.now() + expiresIn * 1000,
            refreshToken,
          },
          { ...accountInfo },
        );
        // FIXME: history.replace(routeShipments) or history.push(routeShipments)
        setTimeout(() => {
          window.location.href = routeDashboard;
        }, 1000);
      } else if (errors && errors.length > 0) {
        throw new Error(errors[0]);
      } else {
        throw new Error('Failed to log in');
      }
    } catch (e: any) {
      if (e.message && e.message.includes('blocked')) setAccountIsBlocked(true);
      setFormState(formStates.ERROR);
      setSubmitting(false);
      setIsPending(false);
    }
  };

  return (
    <>
      <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="card-group d-block d-md-flex row shadow-lg">
                <div className="card col-md-7 p-4 mb-0">
                  <div className="d-flex justify-content-end pt-3 position-relative">
                    <LanguageSwitcher
                      onLanguageChange={onLanguageChange}
                      selectedLanguage={getLanguageByCode(i18n.language)}
                      languages={languages}
                      className={''}
                    />
                  </div>
                  <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                  >
                    {({ errors, touched, isSubmitting }) => (
                      <Form>
                        {isSubmitting && <Loader center={true} cover color="primary" />}
                        <div className="text-center mt-2">
                          <img
                            src="https://customer.laasone.com/images/laasone_logo.png"
                            height={200}
                            alt="logo"
                          />
                        </div>
                        <div className="card-body mt-2 text-center">
                          <h1>{t('LOGIN_PAGE.TITLE')}</h1>
                          <p className="text-medium-emphasis">{t('LOGIN_PAGE.TEXT')}</p>
                          {formState === formStates.ERROR && (
                            <SystemAlert
                              color="error"
                              fullWidth={false}
                              lessPadding
                              onClose={() => setFormState(formStates.PRISTINE)}
                            >
                              <Typography variant="inherit">
                                {!accountIsBlocked
                                  ? t('FAILED_TO_LOG_IN')
                                  : t('FAILED_TO_LOG_IN_BLOCKED')}
                              </Typography>
                            </SystemAlert>
                          )}
                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              <img className="icon" src="/images/free/cil-user.svg" alt="user" />
                            </span>
                            <Field
                              id={'username'}
                              className={clsx('form-control')}
                              name={'username'}
                              placeholder={t('LOGIN_PAGE.USERNAME')}
                            />
                          </div>
                          <div className="input-group mb-4">
                            <span className="input-group-text">
                              <img
                                className="icon"
                                src="/images/free/cil-lock-locked.svg"
                                alt="locked"
                              />
                            </span>
                            <Field
                              id={'password'}
                              className={clsx('form-control')}
                              name={'password'}
                              type="password"
                              placeholder={t('LOGIN_PAGE.PASSWORD')}
                            />
                          </div>
                          <div className="row">
                            <div className="col-12 text-center">
                              <button className="btn btn-primary" type="submit">
                                {t('LOGIN_PAGE.BUTTON')}
                              </button>
                            </div>
                            <div className="col-12 text-center mt-2">
                              <NavLink to={routePaths.forgotPassword}>
                                {t('LOGIN_PAGE.FORGOT_PASSWORD')}
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
                <div className="card col-md-5 bg-login py-5 d-none d-lg-block">
                  <div className="card-body text-center">
                    <img alt="username" src="/images/24-hours.svg" />
                    <h2 className="mt-3">{t('LOGIN_PAGE.SIGN_UP')}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnauthenticatedApp;
