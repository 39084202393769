const translations = {
    BACK: 'Back',
    ACCOUNT: 'Account',
    WELCOME: 'Welcome',
    OVERVIEW: 'Overview',
    ORDERS: 'Orders',
    RFQ: 'RFQ',
    PROFILE: 'Profile',
    CONTACTS: 'Contacts',
    INVOICES: 'Invoices',
    MANAGE: 'Manage',
    PAYMENT: 'Payment',
    COMING_SHIPMENTS: 'Coming shipments',
    IN_PROGRESS_SHIPMENTS: 'In progress shipments',
    Delayed: 'Delayed',
    WAITING_RFQ: 'Waiting RFQ',
    YOU_ARE_LOGGED_OUT: "You are logged out",
    OR: 'OR',
    CANCEL: 'Cancel',
    CONTINUE: 'Continue',
    LOGIN: 'Login',
    LOG_OUT: 'Log out',
    TOTAL: 'Total',
    LOAD_DETAILS: 'LOAD DETAILS',
    FAILED_TO_LOG_IN: 'Failed to log in',
    FAILED_TO_LOG_IN_BLOCKED: 'Account is blocked',
    CLOSE_ALERT: 'Close',
    CONNECTION_LOST: 'Error. Please try again.',
    TRY_AGAIN: 'Please try again',
    NOT_FOUND_HEADING: "Oh no!",
    NOT_FOUND_DESC: "The page you are looking for does not exist or ",
    NOT_FOUND_DESC_2: "you don’t have permissions to see it.",
    NOT_FOUND_ERROR_CODE: "Error code: 404",
    GO_TO_HOMEPAGE: "Go to default page",
    ALL_SHIPMENTS_DONE: "No shipments.",
    STATUS_CHANGED:'Status updated',
    DASHBOARD: {
        TITLE_1: 'Today\'s / Over Time Pickups and Deliveries',
        TITLE_2: 'Coming Pickups and Deliveries'
    },
    DATATABLE: {
        NO_RESULT: 'No records founds',
        SORT: 'Sort',
        next: 'Next Page',
        previous: 'Previous Page',
        rowsPerPage: 'Rows per page',
        displayRows: 'of',
    },
    LOGIN_DATA:{
        TITLE: 'Login information',
        LAST_LOGIN: 'Login data'
    },
    LOGIN_PAGE: {
        TITLE: 'Partner portal',
        TEXT: 'Sign In to your account',
        BUTTON: 'Login',
        USERNAME: 'Username',
        PASSWORD: 'Password',
        FORGOT_PASSWORD: 'Forgot password?',
        SIGN_UP: 'Our system keeps you connected 24/7, ensuring seamless collaboration and helping us serve our customers effectively.',
        SIGN_UPTEXT: 'Heavy transportation has never been this easy',
        AS_SHIPPER: 'Register as Shipper!',
        AS_CARRIER: 'Register as Carrier!'
    },
    FORGOT_PAGE:{
        TITLE: 'Reset your password',
        TEXT: 'To change your password, enter your username',
        BUTTON: 'Login',
        SUBMIT: 'Submit',
        EMAIL_SENT: 'Please check your email to get further instructions how to reset your password.'
    },
    RESET_PASSWORD_BOX_TITLE: "Reset password",
    RESET_PASSWORD_BTN: "Reset",
    DRIVER_VIEW: {
        CHOOSE_LANGUAGE: 'Language',
        CMR_ADDED: 'CMR ADDED',
        CMR_UPLOADED: 'CMR uploaded',
        COLLECTED: 'COLLECTED',
        DELIVERED: 'DELIVERED',
        DELIVERY_REFERENCE: 'Reference',
        DROP_OFF: 'Deliver',
        FROM: 'from',
        INSTRUCTIONS_LOADING: 'Instructions',
        INSTRUCTIONS_DELIVERY: 'Instructions',
        LOADING_REFERENCE: 'Reference',
        ORDER: 'Order',
        PHOTO_OF_SIGNED_CMR: 'Photo of the signed CMR',
        PICKUP: 'Collect',
        SEND_CMR: 'SEND CMR',
        TIME: 'Time',
        TIME_LC: 'time',
        TO: 'to'
    },
    PICKUP_AND_DELIVERY_DATES: 'Pickup and delivery dates',
    PICKUP_FROM: 'Pickup from',
    DELIVERY_TO: 'Delivery to',
    EARLIEST_PICKUP: 'Earliest pickup',
    LATEST_PICKUP: 'Latest pickup',
    EARLIEST_DELIVERY: 'Earliest delivery',
    LATEST_DELIVERY: 'Latest delivery',
    PricesOffered: 'Offer made',
    WaitingPrices: 'Please send your offer',
    Rejected: 'Rejected',
    Expired: 'Expired',
    Approved: 'Approved',
    SPOT_REQUEST: {
        TITLE: 'Spot request',
        TITLE_DESC: 'Please answer to our request and offer your price for this request',
        DETAILS: 'Details',
        CARRIER: 'Carrier',
        OPENED_UNTIL: 'Opened until',
        STATUS: 'Status',
        SHIPMENT: 'Shipment',
        CONTACT_PERSON: 'Contact',
        CONTACT_PERSON_EMAIL: 'Email',
        COMMENT: 'Remarks',
        YOUR_PRICES: 'Leave your prices',
        PRICE: 'Price',
        DELIVERY_TIME: 'Transit days',
        PRICE_COMMENT: 'Comment',
        PRICE_VALID_UNTIL: 'Price valid until',
        ADD_PRICE: 'Add price',
        MAKE_OFFER: 'Send offer',
        REJECT: 'Reject',
        ASTRICS_REQUIRED: '* are required fields',
        SPOT_REQUEST_MODAL: 'Reject',
        REJECT_REASON: 'Reason',
        SUBMIT_SUCCESS: 'Prices offered. Thank you!',
        ORDERER: "Buyer",
        LAASONE_COMMENT: "LaasOne comment",
        NUMBER: "Number",
        DATE:'Date',
        ASKED_FROM:'Recipient',
        PICKUP: "Pickup address",
        DELIVERY: "Delivery address",
        OFFERED_PRICE: "Offered price",
        WAITING_PRICES_UNTIL: "Active until",
    },
    ITEMS_IN_THE_SHIPMENT: 'Items in the shipment',
    SHIPMENT: {
        PICKUP_DELIVERY_CONTACTS: 'Pickup and delivery contact information',
        PICKUP_DELIVERY_NOTES: 'Notes',
        TRANSPORT_DELIVERY_WITH_TAIL_LIFT_TRUCK: 'Delivery with tail lift truck',
        TRANSPORT_WITH_TAIL_LIFT_TRUCK: 'Pickup with tail lift truck',
        TRANSPORT_DANGEROUS_GOODS: 'Includes dangerous goods',
        ITEM: {
            SELECT_TYPE: 'Select type',
            DETAILS: {
                PC: 'pc',
                TYPE: 'Type',
                LENGTH: 'Length',
                LENGTH_TOOLTIP: 'The length is fixed for selected item type',
                WIDTH: 'Width',
                WIDTH_TOOLTIP: 'The width is fixed for selected item type',
                HEIGHT: 'Height',
                VOLUME: 'Volume',
                WEIGHT_PC: 'Weight/pc',
                TOTAL_WEIGHT: 'Total weight',
                LDM: 'LDM',
                LDM_TOOLTIP: 'Loading metres (running metres of trailer length in full width and height). This is automatically calculated based on length, width and quantity, but you can write a different value if needed.',
                REFERENCE_NUMBER: 'Reference number',
                PRODUCT_DESCRIPTION: 'Product description',
                UN_NO: 'UN no',
                UN_NO_TOOLTIP: 'Number, that identifies dangerous goods, hazardous substances and articles',
                UN: 'UN',
                UN_CODE: 'UN code',
                PG: 'PG',
                PG_TOOLTIP: 'Dangerous goods are assigned into 3 packing groups in accordance with the degree of danger they present',
                CLASS: 'Class',
                CLASS_TOOLTIP: 'Classification numbers for the dangerous goods',
                DANGEROUS_GOODS: 'Dangerous goods',
                COLD_FROZEN: 'Cold/frozen',
                STACKABLE: 'Stackable',
                DELICATE: 'Delicate',
                MIN_TEMP: 'Min temp',
                MIN_TEMP_TOOLTIP: 'Minimum temperature of the goods that they require for transport',
                MAX_TEMP: 'Max temp',
                MAX_TEMP_TOOLTIP: 'Maximum temperature of the goods that they require for transport',
                PROPER_SHIPPING_NAME: 'Proper shipping name',
                PROPER_SHIPPING_NAME_TOOLTIP: 'Name to describe the hazard properties and the composition of dangerous goods',
                PROPER_SHIPPING_NAME_PLACEHOLDER: 'Black powder, compressed or Gunpowder',
            },
            TYPE: {
                PALLET: 'Pallet',
                PACKAGE: 'Package',
                TRUCK: 'Truck',
                OTHER: 'Other',
                CONTAINER: 'Container',
                MACHINE:'Machine'
            },
            SUBTYPE: {
                EUR_PALLET: 'EUR pallet',
                FIN_PALLET: 'FIN pallet',
                PALLET: 'Pallet',
                PACKAGE: 'Package',
                BOX: 'Box',
                FULL_TRUCK_LOAD: 'Full truck load',
                LONG_PALLET: 'Long pallet',
                HALF_PALLET: 'Half pallet',
                LDM: 'LDM',
                CONTAINER_20_DC: '20’DC',
                CONTAINER_40_DC: '40’DC',
                CONTAINER_40_HC: '40’HC',
                CONTAINER_OTHER: 'Other',
                MACHINE: 'Machine'
            },
            CLASS: {
                EXPLOSIVES: 'Explosives',
                GASES: 'Gases',
                FLAMMABLE_LIQUIDS: 'Flammable Liquids',
                FLAMMABLE_SOLIDS: 'Flammable Solids',
                OXIDIZERS: 'Oxidizers',
                TOXIC_SUBSTANCES: 'Toxic Substances',
                RADIOACTIVE: 'Radioactive',
                CORROSIVES: 'Corrosives',
                MISCELLANOUS: 'Miscellanous',
            },
            PG: {
                PG1: 'Packing Group I: high danger',
                PG2: 'Packing Group II: medium danger',
                PG3: 'Packing Group III: low danger',
            },
        },
    },
    SWITCH: {
        YES: 'YES',
        NO: 'NO',
    },
    DATEPICKER_PLACEHOLDER: "dd.mm.yyyy",
    'This username already exists': 'This username already exists',
    'This company already exists': 'This company already exists',
    INVITATION_BOX_TITLE: 'Create a new partner portal user',
    PASSWORD_HINT: 'Password minimum length is 8 letters. Require is at least one special characters, number, lowercase / uppercase letter.',
    USERNAME: 'Username',
    PASSWORD: 'Password',
    PASSWORD_AGAIN: 'Password again',
    SIGN_IN: 'Sign in',
    PASSWORDS_DO_NOT_MATCH: 'Passwords do not match',
    USER_NOT_CREATED: 'User not created!',
    CREATE_USER_PASSWORD_ERROR: "Please check password requirements. (Require is at least one special characters, number, lowercase / uppercase letter.)",
    CREATE_USER: 'Create user',
    VALIDATION: {
        IS_REQUIRED_TEXT: 'Required fields are marked with *',
        INVALID_VALUE: 'Invalid value',
        THIS_FIELD_IS_REQUIRED: 'This field is required',
        YOU_HAVE_TO_SELECT_AT_LEAST_ONE_ITEM: 'You have to select at least one item',
        AT_LEAST_ONE_ITEM_NEEDS_TO_BE_ADDED_IN_THE_SHIPMENT:
            'Please select at least 1 item to complete the shipment',
        SOME_REQUIRED_FIELDS_ARE_NOT_FILLED: 'Some required fields are not filled',
        NEW_SHIPMENT_VALIDATION_ERROR: 'The shipment cannot be saved, because there is information missing in the shipment.',
        INVALID_PHONE: 'Invalid phone number',
        INVALID_EMAIL: 'Invalid email address',
        SELECTED_DATE_IS_WRONG: 'Selected date is wrong',
        SELECTED_EARLIEST_DATE_IS_WRONG: 'Please check latest date',
        SELECTED_ARRIVAL_DATE_IS_WRONG: 'Please check earliest delivery date',
        TEMPLATE_NAME: 'Template name must be at least 3 characters'
    },
    USERS: {
        ADD_USER: 'Invite new user',
        EDIT_USER_TITLE: 'Edit user',
        FIRST_NAME: 'First name',
        LAST_NAME: 'Last name',
        USERNAME: 'Username / e-mail',
        BTN_SAVE_BUTTON: "Save",
        INVITED: 'Pending',
        DELETE_SUCCESS: 'User removed',
        DELETE_CONFIRMATION: 'Are you sure you want to remove user?',
        BTN_DELETE: "Delete",
        TEXT_USER_INVITED: "User invitation sent",
        TEXT_USER_SAVED: "User data saved",
        LIMITED_ACCESS_LABEL: "Limited access",
    },
    USERNAME_EXISTS: "This username already exists",
    ORDER: {
        NUMBER: "Number",
        CONSIGNOR: "Consignor",
        PICKUP: "Pickup address",
        PICKUP_DATE: "Pickup date",
        CONSIGNEE: "Consignee",
        DELIVERY: "Delivery address",
        DELIVERY_DATE: "Delivery date",
        ORDERER: "Buyer",
        STATUS: "Status",
        STATUSES:{
            NEW :'New',
            IN_PROGRESS: 'New',
            IN_TRANSIT: 'In transit',
            DELIVERED: 'Delivered',
            CANCELED: 'Canceled',
            PICKED_UP: 'Picked up'
        },
        CHANGE_STATUS:'Change status',
        CHANGE_STATUS_TEXT:'Are you sure you want to change status?'
    },
    INVOICE:{
        TITLE: 'Only self billing invoices are shown in the list',
        NUMBER: 'Number',
        DATE: 'Date',
        Total_SUM:'Total sum'
    },
    PROFILE_PAGE:{
        INFO:'If you notice any errors on your profile, please inform us at info@laasone.com.',
        TITLE_ABOUT:'Company information',
        TITLE_ADDRESS:'Address information',
        TITLE_SELF_BILLING:'Self billing information',
        COMPANY_NAME: 'Company name',
        REGISTER_NUMBER: 'Register number',
        VAT_NUMBER: 'VAT number',
        EMAIL: 'Email',
        DEFAULT_LANGUAGE: 'Default language',
        ADDRESS: 'Address',
        POSTAL_CODE: 'Postal code',
        CITY: 'City',
        STATE: 'State',
        COUNTRY: 'Country',
        SELF_BILLING_ACTIVATED:'Activated',
        PAYMENT_TERMS_DAYS:'Purchase payment terms days',
        BILLING_FREQUENCY:'Billing Frequency',
        INVOICE_EMAIL:'Invoice is sent to email'
    },
    LIST:'List',
    ALERT:{
        ORDER_LIST: 'Your Orders from the Past 30 Days',
        RFQ_LIST: 'Your RFQs from the Past 30 Days',
    },
}

export default translations;