const translations = {
    BACK: 'Tagasi',
    ACCOUNT: 'Konto',
    WELCOME: 'Tere tulemast',
    OVERVIEW: 'Ülevaade',
    ORDERS: 'Tellimused',
    RFQ: 'Päringud',
    PROFILE: 'Profiil',
    CONTACTS: 'Kontaktid',
    INVOICES: 'Arved',
    MANAGE: 'HALDA',
    PAYMENT: 'MAKSED',
    COMING_SHIPMENTS: 'Tulevased saadetised',
    IN_PROGRESS_SHIPMENTS: 'Käimasolevad saadetised',
    Delayed: 'Hilinenud',
    WAITING_RFQ: 'Ootel päringud',
    YOU_ARE_LOGGED_OUT: "Olete välja logitud",
    OR: 'VÕI',
    CANCEL: 'Tühista',
    CONTINUE: 'Jätka',
    LOGIN: 'Logi sisse',
    LOG_OUT: 'Logi välja',
    TOTAL: 'Kokku',
    LOAD_DETAILS: 'Laadimise üksikasjad',
    FAILED_TO_LOG_IN: 'Sisselogimine ebaõnnestus',
    FAILED_TO_LOG_IN_BLOCKED: 'Konto on blokeeritud',
    CLOSE_ALERT: 'Sulge',
    CONNECTION_LOST: 'Ühendus katkenud',
    TRY_AGAIN: 'Viga. Palun proovi uuesti.',
    ALL_SHIPMENTS_DONE: "Hetkel saadetised puuduvad.",
    STATUS_CHANGED:'Staatus uuendatud',
    DASHBOARD: {
        TITLE_1: 'Tänased / hilinenud korjed ja tarned',
        TITLE_2: 'Tulevased korjed ja tarned',
    },
    DATATABLE: {
        NO_RESULT: 'Vasteid ei leitud',
        SORT: 'Sorteeri',
        next: 'Järgmine leht',
        previous: 'Eelmine leht',
        rowsPerPage: 'Ridu lehe kohta',
        displayRows: 'kuni'
    },
    LOGIN_DATA: {
        TITLE: 'Sisselogimise teave',
        LAST_LOGIN: 'Viimane sisselogimine'
    },
    LOGIN_PAGE: {
        TITLE: "Partner portaal",
        TEXT: "Logi sisse oma kontole",
        BUTTON: "Logi sisse",
        USERNAME: "Kasutajanimi",
        PASSWORD: "Parool",
        FORGOT_PASSWORD: "Unustasid parooli?",
        SIGN_UP: "Meie süsteem hoiab teid ühenduses 24/7, tagades sujuva koostöö ja aidates meil kliente tõhusalt teenindada.",
        SIGN_UPTEXT: "Raskeveokite transport pole kunagi olnud nii lihtne",
        AS_SHIPPER: "Registreeru kaubasaatjana!",
        AS_CARRIER: "Registreeru vedajana!"
    },
    FORGOT_PAGE: {
        TITLE: 'Lähtesta oma parool',
        TEXT: 'Parooli muutmiseks sisesta oma kasutajanimi',
        BUTTON: 'Logi sisse',
        SUBMIT: 'Saada',
        EMAIL_SENT: 'Palun kontrollige oma e-posti, et saada täiendavaid juhiseid parooli lähtestamiseks.'
    },
    RESET_PASSWORD_BOX_TITLE: "Muuda parool",
    RESET_PASSWORD_BTN: "Muuda",
    PASSWORD: 'Parool',
    PASSWORD_AGAIN: 'Parool uuesti',
    DRIVER_VIEW: {
        CHOOSE_LANGUAGE: 'Vali keel',
        CMR_ADDED: 'CMR LISATUD',
        CMR_UPLOADED: 'CMR üles laetud',
        COLLECTED: 'PEALEVÕETUD',
        DELIVERED: 'KOHALEVIIDUD',
        DELIVERY_REFERENCE: 'Viide',
        DROP_OFF: 'Tarne',
        FROM: 'kust',
        INSTRUCTIONS_LOADING: 'Juhised',
        INSTRUCTIONS_DELIVERY: 'Juhised',
        LOADING_REFERENCE: 'Viide',
        ORDER: 'Tellimus',
        PHOTO_OF_SIGNED_CMR: 'Foto allkirjastatud CMR-ist',
        PICKUP: 'Korje',
        SEND_CMR: 'SAADA CMR',
        TIME: 'KELL',
        TIME_LC: 'kell',
        TO: 'kuhu',
    },
    PICKUP_AND_DELIVERY_DATES: 'Laadimisajad',
    PICKUP_FROM: 'Korje',
    DELIVERY_TO: 'Tarne',
    EARLIEST_PICKUP: 'Varaseim korje kuupäev',
    LATEST_PICKUP: 'Hiliseim korje kuupäev',
    EARLIEST_DELIVERY: 'Varaseim tarne kuupäev',
    LATEST_DELIVERY: 'Hiliseim tarne kuupäev',
    PricesOffered: 'Pakkumine saadetud',
    WaitingPrices: 'Palun saada oma pakkumine',
    Rejected: 'Keeldutud',
    Expired: 'Aegunud',
    Approved: 'Kinnitatud',
    SPOT_REQUEST: {
        TITLE: 'Hinnapäring',
        TITLE_DESC: 'Palun vasta meie hinnapäringule, sisestades meile oma hinnad',
        DETAILS: 'Detailid',
        CARRIER: 'Partner',
        OPENED_UNTIL: 'Aktiivne kuni',
        STATUS: 'Staatus',
        SHIPMENT: 'Saadetised',
        CONTACT_PERSON: 'Kontakt',
        CONTACT_PERSON_EMAIL: 'E-post',
        COMMENT: 'Märkused',
        YOUR_PRICES: 'Hinnad',
        PRICE: 'Hind',
        DELIVERY_TIME: 'Transiidi päevad',
        PRICE_COMMENT: 'Kommentaar',
        PRICE_VALID_UNTIL: 'Hind aktiivne kuni',
        ADD_PRICE: 'Lisa hind',
        MAKE_OFFER: 'Saada pakkumine',
        REJECT: 'Keeldu',
        ASTRICS_REQUIRED: '* kohustuslikud väljad',
        SPOT_REQUEST_MODAL: 'Keeldu pakkumast hindasid',
        REJECT_REASON: 'Põhjus',
        SUBMIT_SUCCESS: 'Hinnad edukalt sisestatud. Täname!',
        ORDERER: "Ostja",
        LAASONE_COMMENT: "LaasOne-i kommentaar pakutud hindadele",
        NUMBER: "Number",
        DATE: "Kuupäev",
        ASKED_FROM: "Päringu saaja",
        PICKUP: "Pealevõtmise aadress",
        DELIVERY: "Kohaletoimetamise aadress",
        OFFERED_PRICE: "Pakutud hind",
        WAITING_PRICES_UNTIL: "Aktiivne kuni",
    },
    ITEMS_IN_THE_SHIPMENT: 'Saadetise kaubad',
    SHIPMENT: {
        PICKUP_DELIVERY_CONTACTS: 'Pealevõtmise ja kohaletoimetamise kontaktinfo',
        PICKUP_DELIVERY_NOTES: 'Märkused',
        TRANSPORT_DELIVERY_WITH_TAIL_LIFT_TRUCK:'Tarne tagaluugiga autoga',
        TRANSPORT_WITH_TAIL_LIFT_TRUCK: 'Korje tagaluugiga autoga',
        TRANSPORT_DANGEROUS_GOODS: 'Sisaldab ohtlikut kaupa',
        ITEM: {
            SELECT_TYPE: 'Vali tüüp',
            DETAILS: {
                PC: 'tk',
                TYPE: 'Liik',
                LENGTH: 'Pikkus',
                LENGTH_TOOLTIP: 'Pakendi pikkus on sellel pakendi tüübil standardiseeritud',
                WIDTH: 'Laius',
                WIDTH_TOOLTIP: 'Pakendi laius on sellel pakendi tüübil standardiseeritud',
                HEIGHT: 'Kõrgus',
                VOLUME: 'Maht',
                WEIGHT_PC: 'Kaal/tk',
                TOTAL_WEIGHT: 'Kogukaal',
                LDM: 'LDM',
                LDM_TOOLTIP: 'Laadimismeeter (jooksev meeter haagise pikkusest täislaiuses ja -kõrguses)',
                REFERENCE_NUMBER: 'Viitenumber',
                PRODUCT_DESCRIPTION: 'Kauba kirjeldus',
                UN_NO: 'UN kood',
                UN_NO_TOOLTIP: 'ÜRO tunnusnumber, mis identifitseerib ohtlikud ained ja esemed',
                UN: 'UN',
                UN_CODE: 'UN kood',
                PG: 'Pakendigrupp',
                PG_TOOLTIP: 'Ohtlikud kaubad jagatakse oma ohtlikkuse taseme järgi 3 peamisse gruppi',
                CLASS: 'Klass',
                CLASS_TOOLTIP: 'Ohtlike kaupade klassifikatsiooni numbrid',
                DANGEROUS_GOODS: 'Ohtlik kaup',
                COLD_FROZEN: 'Jahutatud/külmutatud',
                STACKABLE: 'Virnastatav',
                DELICATE: 'Õrn kaup',
                MIN_TEMP: 'Min temp',
                MIN_TEMP_TOOLTIP: 'Minimaalne temperatuur, mida kaup vajab transpordiks',
                MAX_TEMP: 'Max temp',
                MAX_TEMP_TOOLTIP: 'Maksimaalne temperatuur, mida kaup vajab transpordiks',
                PROPER_SHIPPING_NAME: 'Ohtliku veose tunnusnimetus',
                PROPER_SHIPPING_NAME_TOOLTIP: 'Nimetus ohtlike kaupade omaduste ja koostise kirjeldamiseks',
                PROPER_SHIPPING_NAME_PLACEHOLDER: 'Must pulber, kokkusurutud, või püssirohi',
            },
            TYPE: {
                PALLET: 'Alus',
                PACKAGE: 'Pakk',
                TRUCK: 'Täiskoorem',
                OTHER: 'Muu',
                CONTAINER: 'Konteiner',
                MACHINE: 'Masin'
            },
            SUBTYPE: {
                EUR_PALLET: 'EUR alus',
                FIN_PALLET: 'FIN alus',
                PALLET: 'Alus',
                PACKAGE: 'Pakk',
                BOX: 'Kast',
                FULL_TRUCK_LOAD: 'Täiskoorem',
                LONG_PALLET: 'Pikk alus',
                HALF_PALLET: 'Poolik alus',
                LDM: 'LDM',
                CONTAINER_20_DC: '20’DC',
                CONTAINER_40_DC: '40’DC',
                CONTAINER_40_HC: '40’HC',
                CONTAINER_OTHER: 'Muu konteiner',
                MACHINE: 'Masin'
            },
            CLASS: {
                EXPLOSIVES: 'Lõhkeained',
                GASES: 'Gaasid',
                FLAMMABLE_LIQUIDS: 'Kergestisüttivad vedelikud',
                FLAMMABLE_SOLIDS: 'Kergestisüttivad tahked ained',
                OXIDIZERS: 'Oksüdeerivad ained',
                TOXIC_SUBSTANCES: 'Mürgised ained',
                RADIOACTIVE: 'Radioaktiivsed materjalid',
                CORROSIVES: 'Sööbivad ained',
                MISCELLANOUS: 'Muud ohtlikud ained ja esemed',
            },
            PG: {
                PG1: 'Pakendirühm I: väga ohtlikud',
                PG2: 'Pakendirühm II: mõõdukalt ohtlikud',
                PG3: 'Pakendirühm III: vähem ohtlikud',
            },
        },
    },
    SWITCH: {
        YES: 'JAH',
        NO: 'EI',
    },
    DATEPICKER_PLACEHOLDER: "dd.mm.yyyy",
    'This username already exists': 'See kasutajanimi on juba kasutusel',
    'This company already exists': 'Sellise registrikoodiga ettevõte on juba registreeritud',
    ORDER: {
        NUMBER: "Number",
        CONSIGNOR: "Saatja",
        PICKUP: "Pealevõtmise aadress",
        PICKUP_DATE: "Kuupäev",
        CONSIGNEE: "Saaja",
        DELIVERY: "Kohaletoimetamise aadress",
        DELIVERY_DATE: "Kuupäev",
        ORDERER: "Ostja",
        STATUS: "Staatus",
        STATUSES: {
            NEW: 'Uus',
            IN_PROGRESS: 'Uus',
            IN_TRANSIT: 'Transiidis',
            DELIVERED: 'Tarnitud',
            CANCELED: 'Tühistatud',
            PICKED_UP: 'Peale võetud'
        },
        CHANGE_STATUS: 'Muuda staatust',
        CHANGE_STATUS_TEXT: 'Kas olete kindel, et soovite staatust muuta?'
    },
    INVOICE: {
        TITLE: 'Nimekirjas kuvatakse ainult isearvelduse arved',
        NUMBER: 'Number',
        DATE: 'Kuupäev',
        Total_SUM: 'Kogusumma'
    },
    PROFILE_PAGE: {
        INFO: 'Kui märkate oma profiilis vigu, palun andke meile teada aadressil info@laasone.com.',
        TITLE_ABOUT: 'Ettevõtte teave',
        TITLE_ADDRESS: 'Aadressi teave',
        TITLE_SELF_BILLING: 'Isearvelduse teave',
        COMPANY_NAME: 'Ettevõtte nimi',
        REGISTER_NUMBER: 'Registrinumber',
        VAT_NUMBER: 'KMKR number',
        EMAIL: 'E-post',
        DEFAULT_LANGUAGE: 'Vaikimisi keel',
        ADDRESS: 'Aadress',
        POSTAL_CODE: 'Postiindeks',
        CITY: 'Linn',
        STATE: 'Maakond',
        COUNTRY: 'Riik',
        SELF_BILLING_ACTIVATED: 'Aktiivne',
        PAYMENT_TERMS_DAYS: 'Ostude maksetähtaeg päevades',
        BILLING_FREQUENCY: 'Arveldussagedus',
        INVOICE_EMAIL: 'Arve saadetakse e-posti aadressile'
    },
    NOT_FOUND_HEADING: "Oh ei!",
    NOT_FOUND_DESC: "Lehte, mida otsite, ei eksisteeri või ",
    NOT_FOUND_DESC_2: "teil puuduvad õigused selle vaatamiseks.",
    NOT_FOUND_ERROR_CODE: "Veakood: 404",
    GO_TO_HOMEPAGE: "Mine avalehele",
    LIST: 'Nimekiri',
    ALERT:{
        ORDER_LIST: 'Teie tellimused viimase 30 päeva jooksul',
        RFQ_LIST: 'Teie päringud viimase 30 päeva jooksul'
    },
    INVITATION_BOX_TITLE: 'Loo uus kasutaja',
    VALIDATION: {
        IS_REQUIRED_TEXT: 'Kohustuslikud väljad on märgitud *',
        INVALID_VALUE: 'Ebasobiv väärtus',
        THIS_FIELD_IS_REQUIRED: 'See väli on kohustuslik',
        YOU_HAVE_TO_SELECT_AT_LEAST_ONE_ITEM: 'Vähemalt üks peab olema valitud',
        SOME_REQUIRED_FIELDS_ARE_NOT_FILLED: 'Mõned nõutud väljad on täitmata',
        NEW_SHIPMENT_VALIDATION_ERROR: 'Saadetist ei saa salvestada, sest osa vajalikku infot on puudu.',
        INVALID_PHONE: 'Ebasobiv telefoninumber',
        INVALID_EMAIL: 'Ebasobiv e-posti aadress',
        SELECTED_DATE_IS_WRONG: 'Valitud kuupäev ei sobi',
        SELECTED_EARLIEST_DATE_IS_WRONG: 'Palun kontrolli varaseimat kuupäeva',
        SELECTED_ARRIVAL_DATE_IS_WRONG: 'Palun kontrolli varaseimat tarnekuupäeva',
    },
    PASSWORDS_DO_NOT_MATCH: 'Paroolid ei kattu!',
    USER_NOT_CREATED: 'Kasutaja loomine ebaõnnestus!',
    CREATE_USER_PASSWORD_ERROR: "Palun kontrolli paroolis nõutud tingimusi. (Nõutav on vähemalt üks erimärk, number, väike-/suurtäht.)",
    CREATE_USER: 'Loo kasutaja',
    PASSWORD_HINT: 'Parooli minimaalne pikkus on 8 tähte. Nõutav on vähemalt üks erimärk, number, väike-/suurtäht.',
}
export default translations;
