import React, { FC, ReactElement, useState } from 'react';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';
import { getLanguageByCode } from 'shared/functions/getLanguageByCode';
import { languages } from 'shared/constants/languages';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { Loader } from '../../components';
import { formStates } from 'shared/constants/formStates';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useSessionApi } from 'shared/services/session';

const ForgotPassword: FC = (): ReactElement => {
  type FormValues = {
    username: string;
  };
  const initialValues: FormValues = {
    username: '',
  };

  const { t, i18n } = useTranslation();
  const { forgotPassword } = useSessionApi();
  const [, setFormState] = useState(formStates.PRISTINE);
  const [, setIsPending] = useState(false);
  const [emailWasSent, setEmailWasSent] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>(initialValues);

  const validationSchema = Yup.object().shape({
    username: Yup.string().min(2, t('INVALID_USERNAME')).required(),
  });

  const onLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const handleSubmit = async (
    { username }: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) => {
    setFormState(formStates.PRISTINE);
    setIsPending(true);
    setEmailWasSent(false);
    try {
      const reset = await forgotPassword({ email: username, token: '' });
      if (reset) {
        setFormValues({ username: '' });
        setEmailWasSent(true);
      }
    } catch (e: any) {
      setFormState(formStates.ERROR);
      setSubmitting(false);
      setIsPending(false);
    }
    setIsPending(false);
  };

  return (
    <>
      <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="card-group d-block d-md-flex row shadow-lg">
                <div className="card col-md-7 p-4 mb-0">
                  <div className="d-flex justify-content-end pt-3 position-relative">
                    <LanguageSwitcher
                      onLanguageChange={onLanguageChange}
                      selectedLanguage={getLanguageByCode(i18n.language)}
                      languages={languages}
                      className={''}
                    />
                  </div>
                  <Formik
                    initialValues={formValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize
                  >
                    {({ errors, isSubmitting }) => (
                      <Form>
                        {isSubmitting && <Loader center={true} cover color="primary" />}
                        <div className="text-center mt-2">
                          <img
                            src="https://customer.laasone.com/images/laasone_logo.png"
                            height={200}
                            alt="logo"
                          />
                        </div>
                        <div className="card-body mt-2 text-center">
                          <h1>{t('FORGOT_PAGE.TITLE')}</h1>
                          <p className="text-medium-emphasis">{t('FORGOT_PAGE.TEXT')}</p>
                          {emailWasSent && (
                            <div className="alert alert-info">{t('FORGOT_PAGE.EMAIL_SENT')}</div>
                          )}
                          <div
                            className={clsx(
                              'input-group mb-3',
                              errors.username && 'validation-error',
                            )}
                          >
                            <Field
                              id={'username'}
                              className={clsx('form-control')}
                              name={'username'}
                              placeholder={t('LOGIN_PAGE.USERNAME')}
                            />
                          </div>
                          <div className="row">
                            {!emailWasSent && (
                              <div className="col-12 text-center">
                                <button className="btn btn-primary" type="submit">
                                  {t('FORGOT_PAGE.SUBMIT')}
                                </button>
                              </div>
                            )}
                            <div className="col-12 text-center mt-2">
                              <NavLink to="/">{t('LOGIN_PAGE.BUTTON')}</NavLink>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
                <div className="card col-md-5 bg-login py-5 d-none d-lg-block">
                  <div className="card-body text-center">
                    <img alt="username" src="/images/24-hours.svg" />
                    <h2 className="mt-3">{t('LOGIN_PAGE.SIGN_UP')}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
