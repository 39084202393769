import React, { FC, ReactElement, useEffect, useState } from 'react';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';
import { getLanguageByCode } from 'shared/functions/getLanguageByCode';
import { languages } from 'shared/constants/languages';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { Loader, SystemAlert, Typography } from '../../components';
import { formStates } from 'shared/constants/formStates';
import clsx from 'clsx';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useSessionApi } from 'shared/services/session';
import { useUser } from 'shared/hooks/useUser';
import { useUserAccountApi } from 'shared/services/user';
import qs from 'qs';
import { routeDashboard } from 'routes';
import { ApiValidationError } from 'types/api';
import { generateKey } from '../../shared/functions/generateKey';

const ResetPassword: FC = (): ReactElement => {
  type FormValues = {
    password: string;
    repassword: string;
  };
  const initialValues: FormValues = {
    password: '',
    repassword: '',
  };

  const { t, i18n } = useTranslation();
  const user = useUser();
  const { logIn, resetPassword } = useSessionApi();
  const { fetchUserAccountInfo } = useUserAccountApi();
  const [, setIsPending] = useState(false);
  const [formValues] = useState<FormValues>(initialValues);
  const location = useLocation();
  const [apiErrors, setApiErrors] = useState<ApiValidationError[]>([]);
  const [notFound, setNotFound] = useState(false);
  const [formState, setFormState] = useState(formStates.PRISTINE);

  const { email, code } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    plainObjects: true,
  });

  const validationSchema = Yup.object().shape({
    password: Yup.string().min(8, t('VALIDATION.INVALID_USERNAME')).required(),
    repassword: Yup.string().min(8, t('VALIDATION.INVALID_USERNAME')).required(),
  });

  const onLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const handleSubmit = async (
    payload: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) => {
    setApiErrors([]);
    setFormState(formStates.PRISTINE);
    setIsPending(true);
    const urlToken = code ? code.toString() : '';
    const username = email ? email.toString().replace(' ', '+') : '';
    try {
      const result = await resetPassword({
        email: username,
        password: payload.password,
        confirmPassword: payload.repassword,
        code: urlToken,
      });
      if (result.validation?.errors?.length > 0) {
        setApiErrors(result.validation?.errors);
        throw new Error('User not created');
      } else {
        setIsPending(true);
      }
      const {
        isBadRequest,
        access_token: accessToken,
        expires_in: expiresIn,
        refresh_token: refreshToken,
        errors,
      } = await logIn({
        username,
        password: payload.password,
      });
      if (!isBadRequest && accessToken) {
        const { payload: accountInfo } = await fetchUserAccountInfo({
          token: accessToken,
        });
        user.logIn(
          {
            username,
            token: accessToken,
            expirationDate: Date.now() + expiresIn * 1000,
            refreshToken,
          },
          { ...accountInfo },
        );
        // FIXME: history.replace(routeShipments) or history.push(routeShipments)
        window.location.href = routeDashboard;
      } else if (errors && errors.length > 0) {
        throw new Error(errors[0]);
      } else {
        throw new Error('Failed to log in');
      }
    } catch (e) {
      setFormState(formStates.ERROR);
      setSubmitting(false);
      setIsPending(false);
    }
  };

  useEffect(() => {
    if (!email || !code) {
      setNotFound(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="card-group d-block d-md-flex row shadow-lg">
                <div className="card col-md-7 p-4 mb-0">
                  <div className="d-flex justify-content-end pt-3 position-relative">
                    <LanguageSwitcher
                      onLanguageChange={onLanguageChange}
                      selectedLanguage={getLanguageByCode(i18n.language)}
                      languages={languages}
                      className={''}
                    />
                  </div>
                  <Formik
                    initialValues={formValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize
                  >
                    {({ errors, isSubmitting }) => (
                      <Form>
                        {isSubmitting && <Loader center={true} cover color="primary" />}
                        <div className="text-center mt-2">
                          <img
                            src="https://customer.laasone.com/images/laasone_logo.png"
                            height={200}
                            alt="logo"
                          />
                        </div>
                        <div className="card-body mt-2 text-center">
                          <h1>{t('RESET_PASSWORD_BOX_TITLE')}</h1>
                          {formState === formStates.ERROR && (
                            <SystemAlert
                              color="error"
                              fullWidth={false}
                              lessPadding
                              onClose={() => setFormState(formStates.PRISTINE)}
                            >
                              {apiErrors.map((item, key) => {
                                return (
                                  <Typography variant="inherit" key={generateKey(key, 'error')}>
                                    {t(item.errorMessage)}
                                  </Typography>
                                );
                              })}
                            </SystemAlert>
                          )}
                          {!notFound && (
                            <>
                              <div
                                className={clsx(
                                  'input-group mb-3',
                                  errors.password && 'validation-error',
                                )}
                              >
                                <Field
                                  id={'password'}
                                  className={clsx('form-control')}
                                  name={'password'}
                                  placeholder={t('PASSWORD')}
                                  type="password"
                                />
                              </div>
                              <div
                                className={clsx(
                                  'input-group mb-3',
                                  errors.repassword && 'validation-error',
                                )}
                              >
                                <Field
                                  id={'repassword'}
                                  className={clsx('form-control')}
                                  name={'repassword'}
                                  placeholder={t('PASSWORD_AGAIN')}
                                  type="password"
                                />
                              </div>
                            </>
                          )}
                          <div className="row">
                            {!notFound && (
                              <div className="col-12 text-center">
                                <button className="btn btn-primary" type="submit">
                                  {t('RESET_PASSWORD_BTN')}
                                </button>
                              </div>
                            )}
                            <div className="col-12 text-center mt-2">
                              <NavLink to="/">{t('LOGIN_PAGE.BUTTON')}</NavLink>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
